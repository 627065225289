import React, { useState, useEffect } from 'react'
import Modal from 'react-modal'
import { Formik } from 'formik'
import AuthService from '../../../services/auth'
import cutileiApi from '../../../services/cutileiApi'
import TimeWindow from '../../../services/timeWindow'
import ScheduleReservation from '../../../services/ScheduleReservation'
import CheckBox from '../../../components/CheckBox'
import Select from '../../../components/Inputs/Select'
import createReservationValidator from '../../../validators/createReservationValidator'
import { ReactComponent as Loading } from '../../../icons/loading2.svg'
import { ReactComponent as ButtonLoading } from '../../../icons/loading.svg'
import * as FA from 'react-icons/fa'
import { DateTime } from 'luxon'

import {
  modalStyle,
  Form,
  FormField,
  Title,
  Input,
  Button,
  CloseButton,
  WeekdayContainer,
  Row,
  ButtonText,
  Label,
  WarningText,
  ErrorContainer
} from './styles'

function CreateReservationModal ({
  visible,
  onConfirm: handleConfirm,
  onClose: handleClose
}) {
  const [errorMessage, setErrorMessage] = useState (null)
  const [weekdays, setWeekdays] = useState ([])
  const [longestDay, setLongestDay] = useState (null)
  const [professionals, setProfessionals] = useState ([])
  const [startTimeWindows, setStartTimeWindows] = useState ([])
  const [endTimeWindows, setEndTimeWindows] = useState ([])
  const [selectAllDays, setSelectAllDays] = useState (false)
  const [loading, setLoading] = useState (true)
  const businessId = AuthService.getBusinessId ()
  const token = AuthService.getToken ()

  const requestConfig = {
    headers: {
      'Authorization': `Bearer ${token}`
    }
  }

  useEffect (() => {
    if (visible) getData ()
    setSelectAllDays (!visible)
  }, [visible])

  const getData = async () => {
    try {
      const { data: weekdays } = await cutileiApi.get ('/weekdays')
      const { data: workingDays } = await cutileiApi.get (`/businesses/${businessId}/working_days`)
      const { data: professionals } = await cutileiApi.get (
        `/businesses/${businessId}/professionals?status=active&access_level=service`,
        requestConfig  
      )

      const longestDay = workingDays.sort (day => (
        DateTime.fromISO (day.opening) < DateTime.fromISO (day.closing)
      ))[0]

      setStartTimeWindows (TimeWindow.getTimeWindows (longestDay.opening, longestDay.closing))
      setEndTimeWindows (TimeWindow.getTimeWindows (longestDay.opening, longestDay.closing))

      setWeekdays (weekdays)
      setLongestDay (longestDay)
      setProfessionals ([
        {
          label: 'Todos',
          value: 'all_professionals'
        },
        ...professionals.map (professional => ({
          label: professional.nickname ?? professional.name.split (' ')[0],
          value: professional.id,
        }))
      ])
    } catch (error) {
      if (error.response.status === 403)
        setErrorMessage (error.response.data.message)
      console.log (error.response)
    } finally {
      setLoading (false)
    }
  }

  const handlePeriodChange = (name, option, values, setFieldValue) => {
    let interval = null
    const { weekdays } = values
    const startDate = DateTime.fromISO (values.startDate)

    switch (option.value) {
      case -2: interval = null; break // Personalizado
      case -1: interval = {years: 500}; break // Indeterminado
      case 30: interval = {months: 1}; break // 1 mês
      default: interval = {days: option.value} // 1 dia - 7 dias - 15 dias
    }

    const endDate = startDate.plus (interval ?? {days: 1}).toISODate ()
    setFieldValue ('endDate', endDate)

    if (option.value === 0)
      setFieldValue ('weekdays', weekdays.map (day => ({
        ...day, selected: day.iso_number === startDate.weekday
      })))
    else
      handleSelectAllDays (option.value <= 15 && option.value !== -1, weekdays, setFieldValue)
    setFieldValue (name, option)
  }

  const handleStartDateSelected = (startDate, values, setFieldValue) => {
    if (values.period?.value === 0)
      setFieldValue ('weekdays', weekdays.map (day => ({
        ...day, selected: day.iso_number === DateTime.fromISO (startDate).weekday
      })))
    setFieldValue ('startDate', startDate)
  }

  const handleEndDateSelected = (endDate, values, setFieldValue) => {
    const { startDate } = values
    const dateInterval = { startDate, endDate }

    handleSelectAllDays (getPeriodInDays (dateInterval) <= 15, weekdays, setFieldValue)
    setFieldValue ('endDate', endDate)
  }

  const handleTimeSelected = (name, option, setFieldValue) => {
    if (name === 'startTime') {
      setEndTimeWindows (TimeWindow.getTimeWindows (option.value, longestDay.closing).filter (tw => 
        tw.value !== option.value
      ))
    } else if (name === 'endTime') {
      setStartTimeWindows (TimeWindow.getTimeWindows (longestDay.opening, option.value).filter (tw => 
        tw.value !== option.value
      ))
    }
    setFieldValue (name, option)
  }

  const handleSelectAllDays = (value, weekdays, setFieldValue) => {
    setFieldValue ('weekdays', weekdays.map (day => ({...day, selected: value})))
    setSelectAllDays (value)
  }

  const getPeriodInDays = values => {
    const startDate = DateTime.fromISO (values.startDate)
    const endDate = DateTime.fromISO (values.endDate)
    const periodInDays = endDate.diff (startDate, 'days').toObject ().days

    return periodInDays
  }

  return (
    <Modal
      isOpen={visible}
      onRequestClose={handleClose}
      shouldCloseOnOverlayClick={true}
      ariaHideApp={false}
      style={modalStyle}
    >
      {loading ? <Loading/> : (
        <Formik
          validationSchema={createReservationValidator}
          initialValues={{
            'professionals': [],
            'description': '',
            'startDate': '',
            'endDate': '',
            'period': null,
            'startTime': null,
            'endTime': null,
            'weekdays': weekdays.map (day => ({...day, selected: false}))
          }}
          onSubmit={async (values, { setSubmitting }) => {
            const {
              professionals,
              description,
              startDate,
              endDate,
              startTime,
              endTime,
              weekdays
            } = values

            const ReservationData = {
              description,
              startDate,
              endDate,
              startTime: startTime.value,
              endTime: endTime.value,
              weekdays: weekdays.filter (day => day.selected)
            }

            try {
              setErrorMessage (null)
              const isAllProfessionals = professionals.some (p => p.value === 'all_professionals')
              let reservations = []

              if (isAllProfessionals) {
                const { data } = await cutileiApi.post (`/businesses/${businessId}/schedule_reservations`, {
                  ...ReservationData,
                  professionalId: 'all_professionals'
                }, requestConfig)

                reservations = [...data]
              } else {
                for (let i = 0; i < professionals.length; i++) {
                  const { data } = await cutileiApi.post (`/businesses/${businessId}/schedule_reservations`, {
                    ...ReservationData,
                    professionalId: professionals[i].value
                  }, requestConfig)
                  reservations.push (data[0])
                }
              }

              setSubmitting (false)
              handleConfirm (reservations)
              handleClose ()
            } catch (error) {
              setSubmitting (false)
              setErrorMessage (error.response?.data.message)
              console.log (error)
            }
          }}
        >
          {({
            values,
            errors,
            touched,
            isSubmitting,
            setFieldValue,
            setFieldTouched,
            handleChange,
            handleBlur,
            handleSubmit
          }) => (
            <Form onSubmit={handleSubmit}>
              <Title>Reservar agenda</Title>
              <CloseButton onClick={handleClose}>
                <FA.FaTimes color='#FF3939' size={18}/>
              </CloseButton>
              <Row>
                <FormField>
                  <Select
                    name='professionals'
                    placeholder='Profissional...'
                    value={values.professionals}
                    options={professionals}
                    isMulti={true}
                    isClearable={true}
                    onRemoveOption={professional => {
                      setFieldValue (
                        'professionals', values.professionals.filter (p => p.value !== professional.value)
                      )
                    }}
                    onChange={setFieldValue}
                    onBlur={setFieldTouched}
                    error={errors.professionals}
                    touched={touched.professionals}
                  />
                  {touched.professionals && errors.professionals && (
                    <WarningText>
                      {errors.professionals}
                    </WarningText>
                  )}
                </FormField>
                <FormField>
                  <Input 
                    placeholder='Descrição da reserva'
                    value={values.description}
                    onChange={handleChange ('description')} 
                    onBlur={handleBlur ('description')}
                  />
                  {touched.description && errors.description && (
                    <WarningText>
                      {errors.description}
                    </WarningText>
                  )}
                </FormField>
              </Row>
              <Row>
                <FormField>
                  <Input
                    type='date'
                    placeholder='Data de início'
                    value={values.startDate}
                    onChange={e => handleStartDateSelected (e.target.value, values, setFieldValue)}
                    onBlur={handleBlur ('startDate')}
                  />
                  {touched.startDate && errors.startDate && (
                    <WarningText>
                      {errors.startDate}
                    </WarningText>
                  )}
                </FormField>
                <FormField style={{marginLeft: 5, marginRight: values.period?.value === -2 ? 5 : 0}}>
                  <Select
                    name='period'
                    placeholder='Período...'
                    value={values.period}
                    options={ScheduleReservation.PERIODS}
                    onChange={(name, option) => handlePeriodChange (name, option, values, setFieldValue)}
                    onBlur={setFieldTouched}
                    error={errors.period}
                    touched={touched.period}
                  />
                  {touched.period && errors.period && (
                    <WarningText>
                      {errors.period}
                    </WarningText>
                  )}
                </FormField>
                {values.period?.value === -2 &&
                  <FormField>
                    <Input
                      type='date'
                      placeholder='Data final'
                      value={values.endDate}
                      onChange={e => handleEndDateSelected (e.target.value, values, setFieldValue)}
                      onBlur={handleBlur ('endDate')}
                    />
                    {touched.endDate && errors.endDate && (
                      <WarningText>
                        {errors.endDate}
                      </WarningText>
                    )}
                  </FormField>
                }
              </Row>
              {values.professionals.length > 0 && (
                <Row>
                  <FormField>
                    <Select
                      name='startTime'
                      placeholder='Horário inicial...'
                      value={values.startTime}
                      options={startTimeWindows}
                      onChange={(name, option) => handleTimeSelected (name, option, setFieldValue)}
                      onBlur={setFieldTouched}
                      error={errors.startTime}
                      touched={touched.startTime}
                    />
                    {touched.startTime && errors.startTime && (
                      <WarningText>
                        {errors.startTime}
                      </WarningText>
                    )}
                  </FormField>
                  <FormField>
                    <Select
                      name='endTime'
                      placeholder='Horário final...'
                      value={values.endTime}
                      options={endTimeWindows}
                      onChange={(name, value) => handleTimeSelected (name, value, setFieldValue)}
                      onBlur={setFieldTouched}
                      error={errors.endTime}
                      touched={touched.endTime}
                    />
                    {touched.endTime && errors.endTime && (
                      <WarningText>
                        {errors.endTime}
                      </WarningText>
                    )}
                  </FormField>
                </Row>
              )}
              {getPeriodInDays (values) > 15 && (
                <FormField style={{marginBottom: 10}}>
                  <Label style={{marginBottom: 5}}>
                    Selecione os dias da semana em que deseja repetir esta reserva
                  </Label>
                  <WeekdayContainer>
                    {weekdays.map ((day, index) => (
                      <Row key={day.id} style={{alignItems: 'center', margin: 0}}>
                        <CheckBox
                          id={`weekdays.${index}.selected`}
                          style={{marginRight: 6}}
                          value={values.weekdays[index].selected}
                          onValueChange={value => setFieldValue (`weekdays.${index}.selected`, value)}
                        />
                        <Label htmlFor={`weekdays.${index}.selected`}>
                          {day.name.substring (0, 3)}
                        </Label>
                      </Row>
                    ))}
                  </WeekdayContainer>
                  <Row style={{alignItems: 'center', marginBottom: 4}}>
                    <CheckBox
                      id='select_all'
                      style={{marginRight: 6}}
                      value={selectAllDays}
                      onValueChange={value => handleSelectAllDays (value, values.weekdays, setFieldValue)}
                    />
                    <Label htmlFor='select_all'>
                      Selecionar todos os dias
                    </Label>
                  </Row>
                  {touched.weekdays && errors.weekdays && (
                    <WarningText>
                      {errors.weekdays}
                    </WarningText>
                  )}
                </FormField>
              )}
              {errorMessage && (
                <ErrorContainer>
                  <ButtonText>{errorMessage}</ButtonText>
                </ErrorContainer>
              )}
              <Button type='submit' disabled={isSubmitting}>
                {isSubmitting ? <ButtonLoading/> : 'Criar reserva'}
              </Button>
            </Form>
          )}
        </Formik>
      )}
    </Modal>
  )
}

export default CreateReservationModal
