import * as Yup from 'yup'

const editReservationValidator = Yup.object ({
  professional: Yup
    .object ()
    .nullable ()
    .required ('Selecione um profissional'),

  description: Yup
    .string ()
    .required ('Insira uma descrição')
    .max (128, 'A descrição deve ter até 128 caracteres'),

  startDate: Yup
    .string ()
    .required ('Selecione uma data de início'),

  endDate: Yup
    .string ()
    .required ('Selecione uma data final')
    .test (
      'is-greater-or-equal',
      'A data final deve ser maior ou igual à data inicial.',
      function (value) {
        const { startDate } = this.parent
        if (!startDate || !value) return true
        return value >= startDate
      }
    ),
  
  period: Yup
    .object ()
    .nullable ()
    .required ('Selecione um período'),

  startTime: Yup
    .object ()
    .nullable ()
    .required ('Selecione o horário inicial'),

  endTime: Yup
    .object ()
    .nullable ()
    .required ('Selecione o horário final'),
})

export default editReservationValidator
