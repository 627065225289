import React, { useState, useEffect } from 'react'
import { Formik } from 'formik'
import cutileiApi from '../../../services/cutileiApi'
import AuthService from '../../../services/auth'
import Select from '../../Inputs/Select'
import MoneyInput from '../../Inputs/MoneyInput'
import editScheduleValidator from '../../../validators/editScheduleValidator'
import { ReactComponent as ButtonLoading } from '../../../icons/loading.svg'
import { ReactComponent as Loading } from '../../../icons/loading2.svg'
import { DateTime } from 'luxon'

import {
  Form,
  FormField,
  Input,
  Label,
  Row,
  Button,
  InfoText,
  WarningText,
  ErrorContainer
} from './styles'

function EditScheduleForm ({
  data: schedule,
  onEdit: handleEdit
}) {
  const { 
    id: schedule_id, member, customer, professional,
    service, date, time, duration, price, cutilei_schedule
  } = schedule

  const [loading, setLoading] = useState (true)
  const [errorMessage, setErrorMessage] = useState (null)
  const [professionals, setProfessionals] = useState ([])
  const [services, setServices] = useState ([])
  const [timeWindows, setTimeWindows] = useState ([])
  const token = AuthService.getToken ()
  const businessId = AuthService.getBusinessId ()
  const scheduleDateTime = DateTime.fromFormat (`${date} ${time}`, 'yyyy-MM-dd H:mm')
  const isWithinServiceDuration = DateTime.now () < scheduleDateTime?.plus ({minutes: duration})
  const isEnamelingService = service.name.toLowerCase ().includes ('esmaltação')
  const businessFilter = `business_id=${businessId}`

  const requestConfig = {
    headers: {
      'Authorization': `Bearer ${token}`
    }
  }

  useEffect (() => {
    getData (schedule)
  }, [schedule])

  const getData = async (schedule) => {
    try {
      const filters = `?status=active&access_level=service&schedule_date=${schedule.date}`
                    + `&schedule_time=${schedule.time}&schedule_duration=${schedule.duration}`
                    + `&schedule_id=${schedule_id}&service_id=${schedule.service.id}`
                    + (cutilei_schedule ? `&category_name=${schedule.service.category.name}` : '')

      const { data: professionals } = await cutileiApi.get (
        `/businesses/${businessId}/professionals${filters}`, requestConfig
      )

      let { data: services } = await cutileiApi.get (
        `/businesses/${businessId}/professionals/${schedule.professional.id}/services`
        + `?cutilei_services=${cutilei_schedule}`,
        requestConfig
      )

      const { data: timeWindows } = await cutileiApi.get (
        `/professionals/${schedule.professional.id}/free_schedules?date=${schedule.date}`
        + `&${businessFilter}&schedule_id=${schedule_id}&service_id=${schedule.service.id}`,
        requestConfig
      )
  
      setTimeWindows (timeWindows.map (tw => ({ label: tw, value: tw })))

      setProfessionals (professionals.map (professional => ({
        label: professional?.nickname ?? professional?.name.split (' ')[0],
        value: professional.id
      })))

      if (cutilei_schedule) services = services.filter (s => (
        s.id !== schedule.service.id
        && !s.name.toLowerCase ().includes ('pé e mão')
        && !s.name.toLowerCase ().includes ('esmaltação')
      ))

      setServices (services.map (service => ({
        label: service.name, value: service
      })))
    } catch (error) {
      console.log (error)
    } finally {
      setLoading (false)
    }
  }

  return loading ? (
    <Loading style={{marginBlock: 20, alignSelf: 'center'}}/>
  ) : (
    <Formik
      validationSchema={editScheduleValidator}
      initialValues={{
        'customer': {
          label: member?.name || customer?.name,
          value: member?.id || customer.id
        },
        'professional': {
          label: professional.nickname || professional.name?.split (' ')[0],
          value: professional.id
        },
        'service': {label: service.name, value: service},
        'date': date,
        'time': {label: time, value: time},
        'price': price
      }}
      onSubmit={async (values, { setSubmitting }) => {
        const scheduleValues = {
          ...values,
          businessId,
          time: values.time.value,
          customerId: values.customer.value,
          serviceId: values.service.value.id,
          professionalId: values.professional.value
        }

        try {
          const { data: schedule } = await cutileiApi.put (
            `/schedules/${schedule_id}`, scheduleValues, requestConfig
          )

          setSubmitting (false)
          handleEdit (schedule)
        } catch (error) {
          console.log (error)
          if (error.response.data) setErrorMessage (error.response.data.message)
          setSubmitting (false)
        }
      }}
    >
      {function EditScheduleForm ({
        values,
        errors,
        touched,
        dirty,
        isSubmitting,
        setFieldValue,
        setFieldTouched,
        handleChange,
        handleBlur,
        handleSubmit
      }) {
        useEffect (() => {
          if (dirty) getData ({
            date: values.date,
            time: values.time.value,
            service: values.service.value,
            duration: values.service.value.duration ?? duration,
            professional: { ...values.professional, id: values.professional.value }
          })
        }, [values.professional, values.service, values.date])

        return (
          <Form onSubmit={handleSubmit}>
            <Row>
              <FormField>
                <Label>Profissional</Label>
                <Select
                  name='professional'
                  placeholder='Profissional...'
                  value={values.professional}
                  options={professionals}
                  onChange={setFieldValue}
                  onBlur={setFieldTouched}
                  error={errors.professional}
                  touched={touched.professional}
                />
                {touched.professional && errors.professional && (
                  <WarningText>
                    {errors.professional}
                  </WarningText>
                )}
              </FormField>
              <FormField>
                <Label>Serviço</Label>
                {!schedule.cutilei_schedule || (
                  schedule.cutilei_schedule && schedule.validated
                  && isWithinServiceDuration && !isEnamelingService
                ) ? (
                  <Select
                    name='service'
                    placeholder='Serviço...'
                    noOptionsMessage='Este profissional ainda não possui serviços cadastrados'
                    value={values.service}
                    options={services}
                    onChange={(name, option) => {
                      setFieldValue ('price', option.value.price)
                      setFieldValue (name, option)
                    }}
                    onBlur={setFieldTouched}
                    error={errors.service}
                    touched={touched.service}
                  />
                ) : (
                  <Input type='text' value={values.service.label} disabled/>
                )}
                {touched.service && errors.service && (
                  <WarningText>
                    {errors.service}
                  </WarningText>
                )}
              </FormField>
            </Row>
            <Row>
              <FormField>
                <Label>Data</Label>
                {schedule.cutilei_schedule ? (
                  <Input
                    type='text'
                    value={DateTime.fromISO (values.date).toFormat ('dd/MM/yyyy')}
                    disabled
                  />
                ) : (
                  <Input
                    type='date'
                    placeholder='Data'
                    value={values.date}
                    onChange={handleChange ('date')} 
                    onBlur={handleBlur ('date')}
                  />
                )}
                {touched.date && errors.date && (
                  <WarningText>
                    {errors.date}
                  </WarningText>
                )}
              </FormField>
              <FormField>
                <Label>Horário</Label>
                {schedule.cutilei_schedule ? (
                  <Input type='text' value={values.time.label} disabled/>
                ) : (
                  <Select
                    name='time'
                    placeholder='Horário...'
                    value={values.time}
                    options={timeWindows}
                    onChange={setFieldValue}
                    onBlur={setFieldTouched}
                    error={errors.time}
                    touched={touched.time}
                  />
                )}
                {touched.time && errors.time && (
                  <WarningText>
                    {errors.time}
                  </WarningText>
                )}
              </FormField>
              {!schedule.cutilei_schedule && (
                <FormField>
                  <Label>Preço</Label>
                  <MoneyInput
                    name={'price'}
                    placeholder='Preço'
                    value={values.price}
                    onValueChange={setFieldValue}
                    onBlur={handleBlur ('price')}
                  />
                </FormField>
              )}
            </Row>
            {errorMessage && (
              <ErrorContainer>
                <InfoText>{errorMessage}</InfoText>
              </ErrorContainer>
            )}
            {dirty && (
              <Button type='submit'>
                {isSubmitting ? <ButtonLoading/> : 'Salvar alterações'}
              </Button>
            )}
          </Form>
        )
      }}
    </Formik>
  )
}

export default EditScheduleForm
